/*
Font: 		BOLONAT
Style: 		HAND
URL: 		https://www.youworkforthem.com/font/T11251/bolonat-hand
Foundry: 	Lee Iley
Foundry: 	https://www.youworkforthem.com/designer/1170/lee-iley
Copyright:	2014
Version: 	17
Created:	September 11, 2019
License: 	https://www.youworkforthem.com/font-license 
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted 
			from using the WebFonts(s) listed without a purchased license. 
			All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
	font-family: 'Bolonat-Hand';
	src: url('../fonts/bolonat-hand.eot');
	src: url('../fonts/bolonat-hand.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/bolonat-hand.woff2') format('woff2'),
	     url('../fonts/bolonat-hand.woff') format('woff'),
	     url('../fonts/bolonat-hand.ttf') format('truetype'),
	     url('../fonts/bolonat-hand.svg#youworkforthem') format('svg');
	font-weight: normal;
	font-style: normal;
}
