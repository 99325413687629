@import './assets/styles/fonts.scss';
@import '~bootstrap/scss/bootstrap';

$fafsa-blue: #0091B3;
$fafsa-purple: #38165C;
$screen-lg: map-get($grid-breakpoints, lg);
$screen-md: map-get($grid-breakpoints, md);
$screen-sm: map-get($grid-breakpoints, sm);
$screen-xs: map-get($grid-breakpoints, xs);

// @import './assets/styles/debug.scss';

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}
btn.info {
  background-color: $fafsa-blue;
  border-color: $fafsa-blue;
}

.container {
  color: #ffffff;
}
.bolonat.heading {
  font-family: Bolonat-Hand;
}
.heading {
  text-align: center;
  font-size: 2.5rem;
  line-height: 3rem;
}
.hero-video {
  background: transparent linear-gradient(180deg, #6441A5 0%, #42206D 45%, #2A0845 100%) 0% 0% no-repeat padding-box;
  padding-top: 1.625rem;
  text-align: center;
  
  a, a:focus, a:visited, a:hover {
    text-decoration: none;
    color: #ffffff;
  }
  .heading {
    padding-top: 3.125rem;
    font-size: 3.125rem;
    line-height: 3.75rem;
    padding-bottom: 1.125rem;
  }
  .video {
    max-width: 600px;
    margin: 0 auto;
  }
}
.glitch {
  height: 20px;
  background-image: url('assets/images/glitch_02a.png');
  background-size: cover;
}
.glitch.first {
  background-image: url('assets/images/glitch_01a.png');
}
.glitch.last {
  background-image: url('assets/images/glitch_03a.png');
}

.why {
  //height: 290px;
  position: relative;

  @media (min-width: $screen-md) {
    height: 290px;
  }

  .text {
    padding: 20px;
    line-height: 1.875rem;
    align-self: center;
    width: 78%;    
  }
  .no-image {
    display: flex;
    background-color: $fafsa-blue;
    font-size: 1.625rem;
    justify-content: center;
  }
  .image {
    max-width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: $screen-md) {
      background-position: right;
    }
    @media (min-width: $screen-lg) {
      background-position: left;
    }
  }
  .heading {
    position: absolute;
    top: 2.225rem;
  }
}
.image.career {
  background-image: url('assets/images/career_img.png');
}
.image.student {
  background-image: url('assets/images/student_img.png');
}
.image.grads {
  background-image: url('assets/images/grads_img.png');
}
.how {
  padding: 2.225rem 0;
  color: $fafsa-purple;
  text-align: center;

  .card-group {
    padding-top: 1.25rem;
     
    .card {
      border: none;
      // margin: 0 var(--bs-card-group-margin);
    }
  }
  .card-img-top {
    max-width: 158px;
    margin: 0 auto;
  }
  .details {
    padding-top: 1.25rem;
    font-size: 1.5rem;

    a, a:focus, a:visited {
      text-decoration: underline;
      color: $fafsa-purple;
    }
  }
}
.where {
  padding: 2.225rem 0;
  background-color: #E46D2B;

  a, a:focus, a:visited, a:hover {
    text-decoration: none;
    color: #ffffff;
  }  

  .row {
    padding-top: 1.25rem;
  }
  .tools {
    font-size: 1.375rem;
    text-align: center;
    display: flex;
    flex-direction: column;

    .spacer {
      flex-grow: 2;
    }

    .btn {
      align-self: center;
    }
  }
}
.footer {
  background-color: $fafsa-purple;
  font-size: .875rem;
  height: 58px;

  a, a:focus, a:visited, a:hover {
    text-decoration: none;
    color: #ffffff;
  }  
  ul {
    line-height: 1rem;
  }
  li:first-child {
    border-right: 1px solid white;
    padding-right: .5rem;
  }

  .col {
    align-self: center;
  }
}
#whydoit {  
  .text {
    @media (max-width: $screen-md) {
      padding-top: 116px;
    }
  }
}
#more {
  display: block;
  padding: 1rem 0 .33rem;

  svg {
    font-size: 2em;
  }
}

@include media-breakpoint-up(sm) {
  .how {
    .card-group {
      .card {
          margin: 0 var(--bs-card-group-margin);
      }
    }
  }
}